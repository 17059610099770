import { graphql } from "gatsby";
import * as React from "react";
import Feature from "../../components/feature/feature";
import Layout from "../../components/lab/layout";
import WorkList from "../../components/works/work-list";
import WorkListItem from "../../components/works/work-list-item";
import { FeatureModel } from "../../models/FeatureModel";
import { ListItemModel } from "../../models/ListItemModel";

type DataProps = {
  data: {
    allWpLab: any;
    wpPage: {
      select_feature: {
        features: FeatureModel[];
      };
    };
  };
};

const LabTopPage = ({ data }: DataProps) => {
  // console.log(data);

  const features = data.wpPage.select_feature.features;

  const [headerColor, setHeaderColor] = React.useState(() => {
    if (features.length > 0) {
      return features[0].featureHeaderColor ?? "white";
    } else {
      return "white";
    }
  });
  const [scrolled, setScrolled] = React.useState(false);
  const divRef = React.useRef<HTMLDivElement>(null);

  const changeHeaderColor = (value: "white" | "black") => {
    if (scrolled) {
      setHeaderColor("white");
    } else {
      setHeaderColor(value);
    }
  };

  const onScroll = React.useCallback(() => {
    const target = divRef.current;
    if (target) {
      const offset = target.offsetTop;
      const scroll = window.scrollY;
      const scrolled = offset <= scroll;
      setScrolled(scrolled);
      if (scrolled && headerColor === "black") {
        setHeaderColor("white");
      }
    }
  }, [headerColor]);

  React.useEffect(() => {
    document.addEventListener("scroll", onScroll, { passive: true });
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [headerColor]);

  const items: ListItemModel[] = data.allWpLab.nodes;

  return (
    <Layout textColor={headerColor}>
      <main>
        <Feature features={features} onChange={changeHeaderColor} />
        <div ref={divRef}></div>
        {items.map((item, i) => {
          return (
            <WorkListItem
              key={i}
              color="white"
              path="/lab/work/"
              item={item}
              showLabel={false}
            />
          );
        })}
      </main>
    </Layout>
  );
};

export default LabTopPage;

export const pageQuery = graphql`
  query {
    allWpLab(sort: { fields: [date], order: DESC }, limit: 9) {
      nodes {
        title
        title_en {
          titleEn
        }
        slug
        date
        thumbnail {
          thumbnailContent {
            type
            movie {
              guid
            }
            images {
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                    fluid {
                      originalImg
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    wpPage(slug: { eq: "lab-feature" }) {
      slug
      select_feature {
        features {
          featureHeaderColor
          featureArticle {
            ... on WpWork {
              slug
              contentTypeName
              blocks {
                block {
                  contents {
                    usingFeature
                    type
                    picture {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                          fluid {
                            originalImg
                          }
                        }
                      }
                    }
                    pictureMobile {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                          fluid {
                            originalImg
                          }
                        }
                      }
                    }
                    movieUrl
                    movie {
                      guid
                    }
                    movieMobile {
                      guid
                    }
                  }
                }
              }
            }
            ... on WpLab {
              slug
              contentTypeName
              blocks {
                block {
                  contents {
                    usingFeature
                    type
                    picture {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                          fluid {
                            originalImg
                          }
                        }
                      }
                    }
                    pictureMobile {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                          fluid {
                            originalImg
                          }
                        }
                      }
                    }
                    movieUrl
                    movie {
                      guid
                    }
                    movieMobile {
                      guid
                    }
                  }
                }
              }
            }
            ... on WpNews {
              slug
              contentTypeName
              blocks {
                block {
                  contents {
                    usingFeature
                    type
                    picture {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                          fluid {
                            originalImg
                          }
                        }
                      }
                    }
                    pictureMobile {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
                          fluid {
                            originalImg
                          }
                        }
                      }
                    }
                    movieUrl
                    movie {
                      guid
                    }
                    movieMobile {
                      guid
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
